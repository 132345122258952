import { createContextProvider } from '@solid-primitives/context'
import { createEffect, createMemo, createSignal, on } from 'solid-js'
import { isServer } from 'solid-js/web'
import { useServerContext } from 'solid-start'
import Cookies from 'universal-cookie'

const { VITE_APP_DOMAIN } = import.meta.env

const [RequestedContextProvider, useRequestedContext] = createContextProvider(() => {
  const event = useServerContext()
  const reader = new Cookies(isServer ? event.request.headers.get('cookie') : document)

  const [store, setStore] = createSignal(new Set<number>(reader.get<number[]>('requested') ?? []))

  const has = (id: number) => {
    return store().has(id)
  }

  const add = (id: number) => {
    store().add(id)
    setStore(new Set(store()))
  }

  const remove = (id: number) => {
    store().delete(id)
    setStore(new Set(store()))
  }

  const expires = new Date()
  expires.setDate(expires.getDate() + 14)

  const writer = new Cookies(undefined, {
    domain: VITE_APP_DOMAIN,
    expires,
    path: '/',
    sameSite: 'strict',
    secure: true
  })

  createEffect(on(store, store => {
    writer.set('requested', [...store])
  }, { defer: true }))

  const ids = createMemo(() => ({ ids: [...store()] }))

  return {
    add,
    has,
    ids,
    remove
  }
})

export { RequestedContextProvider, useRequestedContext }
