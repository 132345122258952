import { useNavigate } from 'solid-start'

export function Fallback(_error: Error) {
  const navigate = useNavigate()
  return <>{_error}</>
  return <>
    {(() => {
      navigate('/error')
    })()}
  </>
}
