import { Show } from 'solid-js'
import { Div } from '~/components/html'
import { useRequestedContext } from '~/contexts'
import { css, font, position, shape } from '~/libs'

type Props = { id: number, position: Parameters<typeof position>[0] }

export function Requested(props: Props) {
  const ctx = useRequestedContext()
  if (!ctx) throw new Error('ctx is not defined')
  const styled = {
    root: css({
      ...position(props.position),
      ...shape({ backgroundColor: '#222', borderRadius: '4px', padding: '3px 10px' }),
      ...font({ color: '#fff', fontSize: '12px', fontWeight: 600, lineHeight: '16px', whiteSpace: 'nowrap' })
    })
  }
  return (
    <Show when={ctx.has(props.id)}>
      <Div class={styled.root}>
        リクエスト済み
      </Div>
    </Show>
  )
}
