import { ErrorBoundary, Show, Suspense } from 'solid-js'
import { Fallback } from '~/components/ui'

type Props<T> = {
  component: (data: T) => JSXElement
  data: Resource<T>
}

export function Async<T>({ component, data }: Props<T>) {
  return (
    <ErrorBoundary fallback={(error: Error) => <Fallback {...error} />}>
      <Suspense>
        <Show keyed when={data()}>
          {data => (
            component(data)
          )}
        </Show>
      </Suspense>
    </ErrorBoundary>
  )
}
